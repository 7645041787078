import { useState } from 'react';
import key from 'weak-key';

// components
import { Lightbox } from 'components/lightbox/lightbox';
import {
  BUTTON_COLORS,
  BUTTON_ALIGNMENT,
  BUTTON_BACKGROUND,
  BUTTON_TYPES,
  Button,
} from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { Button as GDDSButton } from '@geberit/gdds';

type ToolEmbedProps = {
  src: string;
  label: string;
  lightBoxStyle?: React.CSSProperties;
  hasInedit?: boolean;
  hasArrow?: boolean;
  buttonStyle?: 'primary' | 'secondary';
  useGddsButton?: boolean;
};

export function ToolEmbed({
  src,
  label,
  lightBoxStyle,
  hasInedit = false,
  hasArrow = false,
  buttonStyle = 'primary',
  useGddsButton = false,
}: Readonly<ToolEmbedProps>) {
  const [modalOpen, setModalOpen] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const renderButton = () => {
    if (useGddsButton) {
      return (
        <GDDSButton stretched onClick={() => setModalOpen(true)} stylingType={buttonStyle}>
          <InlineEdit previewId={hasInedit ? '#st_buttonText' : undefined}>{label}</InlineEdit>
        </GDDSButton>
      );
    } else {
      return (
        <Button
          color={buttonStyle === 'primary' ? BUTTON_COLORS.WHITE : BUTTON_COLORS.BLACK}
          background={
            buttonStyle === 'primary' ? BUTTON_BACKGROUND.PRIMARY : BUTTON_BACKGROUND.SECONDARY
          }
          align={BUTTON_ALIGNMENT.CENTER}
          symbol={hasArrow ? 'arrow-link-bold-right' : ''}
          onClick={() => setModalOpen(true)}
        >
          <InlineEdit previewId={hasInedit ? '#st_buttonText' : undefined}>{label}</InlineEdit>
        </Button>
      );
    }
  };

  return (
    <div className="c-toolembed">
      {renderButton()}
      {modalOpen && (
        <Lightbox
          additionalClass="c-toolembed-lightbox"
          additionalStyle={lightBoxStyle}
          onCloseRequest={() => setModalOpen(false)}
        >
          <Button
            onClick={() => setModalOpen(false)}
            className="c-lightbox__close"
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            noTracking
          >
            <Translation id="web20_lightbox_close" />
          </Button>
          <div className="c-lightbox__container">
            <div className="c-toolembed__wrapper">
              {!iframeLoaded && <Loader />}
              <iframe
                src={src}
                title={key({ src })}
                height="100%"
                width="100%"
                onLoad={() => setIframeLoaded(true)}
              />
            </div>
          </div>
        </Lightbox>
      )}
    </div>
  );
}
