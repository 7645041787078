// styles
import styles from './styles.module.scss';

// components
import { ToolEmbed } from 'components/ToolEmbed/ToolEmbed';
import { buttonClick } from 'components/Button/trackingActions';
import { CmsLink } from 'components/Link/CmsLink';

//utils
import { useTracking } from 'utils/hooks/useTracking';
import { isEmpty } from 'utils/is-empty';
import { decodingContent } from 'utils/decodingContent';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { classNameBuilder } from 'utils/classNameBuilder';

export interface CTAButtonProps {
  alignment: string;
  text: string;
  border: string;
  link: Link & { url: string };
  contentIndex: number;
  titleInAnchor: boolean;
  buttonStyle?: 'primary' | 'secondary';
}

export function CTAButton({
  alignment,
  text,
  border,
  link,
  contentIndex,
  titleInAnchor,
  buttonStyle = 'primary',
  ...props
}: Readonly<CTAButtonProps>) {
  const track = useTracking();
  const { trackClick } = useTracking2();
  const sectionId = useSectionId('', '', titleInAnchor, contentIndex);
  const borderClass = border ? styles.disableBorder : '';

  if (isEmpty(link)) return null;

  const { type, url, showArrow } = link || {};
  const hasIframe = type === 'iframe';

  /**
   * Add Button tracking on link click
   * @param {*} e
   */
  const trackOnClick = (e) => {
    if (!isEmpty(e.currentTarget.innerText)) {
      track.trackEvent(buttonClick(e.currentTarget.innerText));
      trackClick({
        click_intent: 'cta',
        click_element: 'button',
        click_text: e.currentTarget.innerText,
        click_url: link.target || link.url,
      });
    }
  };

  return (
    <div className="grid-container" id={sectionId}>
      <div className={`grid-x align-${alignment} ${styles.ctaButton} ${borderClass}`}>
        {hasIframe ? (
          <ToolEmbed
            hasArrow={showArrow}
            src={url}
            label={decodingContent(text)}
            buttonStyle={buttonStyle}
            {...props}
          />
        ) : (
          <CmsLink
            link={link}
            className={classNameBuilder(
              'button',
              buttonStyle === 'primary' ? 'button--white' : 'button--black',
              buttonStyle === 'primary'
                ? 'button--background-primary'
                : 'button--background-secondary',
            )}
            tracking={trackOnClick}
          >
            {decodingContent(text)}
          </CmsLink>
        )}
      </div>
    </div>
  );
}
